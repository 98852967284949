import dayjs from "dayjs";
export default {
 data() {
  return {
   time: "",
  };
 },
 methods: {
  generateDateRange(startDate, endDate) {
   const start = dayjs(startDate);
   const end = dayjs(endDate);
   const dateArray = [];

   for (
    let date = start;
    date.isBefore(end) || date.isSame(end);
    date = date.add(1, "day")
   ) {
    dateArray.push(date);
   }

   return dateArray;
  },
  validatePhoneNumber() {
   const phoneRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
   if (!phoneRegex.test(this.searchDestNumber)) {
    this.phoneError = "Veuillez entrer un numéro de téléphone valide";
   } else {
    this.phoneError = null;
   }
  },
  timeFormater(time) {
   // if (time > 3600) {
   //   return new Date(time * 1000).toISOString().slice(11, 19);
   // } else {
   return new Date(time * 1000).toISOString().slice(11, 19);
   // }
  },
  toLocaleDateString(date) {
   const dateCreated = new Date(date);

   const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
   };
   return dateCreated.toLocaleDateString("fr-FR", options);
  },
  toLocaleTimeString(time) {
   const dateCreated = new Date(time);
   const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
   };
   return dateCreated.toLocaleTimeString("fr-FR", options);
  },
  formatDate(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear() +
    " " +
    this.$t("at") +
    " " +
    ("0" + dateCreated.getHours()).slice(-2) +
    ":" +
    ("0" + dateCreated.getMinutes()).slice(-2);

   return formatedDate;
  },
  formatDateNoTime(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear();

   return formatedDate;
  },
  formatDateFrench(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear() +
    " à " +
    ("0" + dateCreated.getHours()).slice(-2) +
    ":" +
    ("0" + dateCreated.getMinutes()).slice(-2);

   return formatedDate;
  },
  formatSecsToMinAndSecs(value) {
   if (value && value > 0) {
    return new Date(value * 1000).toISOString().substring(11, 19);
   }
   var minutes = Math.floor(value / 60).toFixed(0);
   var seconds = (value - minutes * 60).toFixed(0);
   return minutes + "(min)" + ":" + seconds + "(s)";
  },
  formatSecsToHourMinAndSecs(value) {
   let formated = undefined;
   if (value) {
    if (value > 86400) {
     let quotient = Math.floor(value / 86400);
     let remainder = value % 86400;
     formated =
      quotient +
      "." +
      new Date(remainder * 1000).toISOString().substring(11, 19);
    } else {
     formated = new Date(value * 1000).toISOString().substring(11, 19);
    }
    return formated;
   }
  },
  formatSecsToHourMinAndSecs(value) {
   let formated = undefined;
   if (value) {
    if (value > 86400) {
     let quotient = Math.floor(value / 86400);
     let remainder = value % 86400;
     formated =
      quotient +
      "." +
      new Date(remainder * 1000).toISOString().substring(11, 19);
    } else {
     formated = new Date(value * 1000).toISOString().substring(11, 19);
    }
    return formated;
   }
  },

  formatSecsToHourMinAndSecsNoDays(value) {
   let formated = undefined;
   if (value > 86400) {
    let quotient = Math.floor(value / 86400);
    let remainder = value % 86400;
    formated =
     parseInt(quotient) * 24 +
     parseInt(new Date(remainder * 1000).toISOString().substring(11, 13)) +
     new Date(remainder * 1000).toISOString().substring(13, 19);
   } else {
    formated = new Date(value * 1000).toISOString().substring(11, 19);
   }
   return formated;
  },
  parseDateDayMonthYearHifen(date) {
   let newDate = new Date(date);
   let year = newDate.getFullYear();
   let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
   let day = ("0" + newDate.getDate()).slice(-2);
   // let hours = ("0" + newDate.getHours()).slice(-2);
   // let minutes = ("0" + newDate.getMinutes()).slice(-2);
   // let seconds = ("0" + newDate.getSeconds()).slice(-2);
   let fullDate = year + "-" + month + "-" + day;
   return String(fullDate);
  },
  getFirstDayOfMonth(year, month) {
   let date = new Date(year, parseInt(month) - 1, 1);
   return date;
  },
  getLastDayOfMonth(year, month) {
   let date = new Date(year, parseInt(month), 0);
   return date;
  },
  daysInMonth(month, year) {
   return new Date(year, month, 0).getDate();
  },
  formatDateApiRequest(date) {
   let newDate = new Date(date);
   let year = newDate.getFullYear();
   let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
   let day = ("0" + newDate.getDate()).slice(-2);
   let formated = String(year) + String(month) + String(day);
   return formated;
  },
  formatDateHyphen(date) {
   const year = date.getFullYear();
   const month = String(date.getMonth() + 1).padStart(2, "0");
   const day = String(date.getDate()).padStart(2, "0");
   return `${year}-${month}-${day}`;
  },
  getCurrentDay() {
   const today = new Date();
   return {
    firstDay: this.formatDateHyphen(today),
    lastDay: this.formatDateHyphen(today),
   };
  },
  getYesterday() {
   let today = new Date();
   today.setDate(today.getDate() - 1);
   return {
    firstDay: this.formatDateHyphen(today),
    lastDay: this.formatDateHyphen(today),
   };
  },
  getCurrentWeek() {
   const today = new Date();
   const firstDay = new Date(today.setDate(today.getDate() - today.getDay()));
   const lastDay = new Date(
    today.setDate(today.getDate() - today.getDay() + 6)
   );
   return {
    firstDay: this.formatDateHyphen(firstDay),
    lastDay: this.formatDateHyphen(lastDay),
   };
  },
  getLastWeek() {
   const today = new Date();
   const lastWeekFirstDay = new Date(today);
   lastWeekFirstDay.setDate(today.getDate() - today.getDay() - 6); // Adjust for the first day of the week
   const lastWeekLastDay = new Date(today);
   lastWeekLastDay.setDate(today.getDate() - today.getDay() - 1);
   return {
    firstDay: this.formatDateHyphen(lastWeekFirstDay),
    lastDay: this.formatDateHyphen(lastWeekLastDay),
   };
  },
  getCurrentMonth() {
   const today = new Date();
   const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
   const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
   return {
    firstDay: this.formatDateHyphen(firstDay),
    lastDay: this.formatDateHyphen(lastDay),
   };
  },
  getLastMonth() {
   const today = new Date();
   const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
   const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
   return {
    firstDay: this.formatDateHyphen(firstDay),
    lastDay: this.formatDateHyphen(lastDay),
   };
  },
  getLast3Months() {
   const today = new Date();
   const firstDay = new Date(today.getFullYear(), today.getMonth() - 3, 1);
   const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
   return {
    firstDay: this.formatDateHyphen(firstDay),
    lastDay: this.formatDateHyphen(lastDay),
   };
  },
  getLast6Months() {
   const today = new Date();
   const firstDay = new Date(today.getFullYear(), today.getMonth() - 6, 1);
   const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
   return {
    firstDay: this.formatDateHyphen(firstDay),
    lastDay: this.formatDateHyphen(lastDay),
   };
  },
  getLast12Months() {
   const today = new Date();
   const firstDay = new Date(today.getFullYear(), today.getMonth() - 12, 1);
   const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
   return {
    firstDay: this.formatDateHyphen(firstDay),
    lastDay: this.formatDateHyphen(lastDay),
   };
  },
  getWeekNumber(dateString) {
   let date = new Date(
    dateString.slice(0, 4),
    dateString.slice(4, 6) - 1,
    dateString.slice(6, 8)
   );
   let firstDayOfYear = new Date(date.getFullYear(), 0, 1);
   let pastDaysOfYear = (date - firstDayOfYear) / 86400000;
   return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7); // get week number
  },
  getWeekRange(dateString) {
   let date = new Date(
    dateString.slice(0, 4),
    dateString.slice(4, 6) - 1,
    dateString.slice(6, 8)
   );
   let dayOfWeek = date.getDay();
   let firstDayOfWeek = new Date(date);
   let lastDayOfWeek = new Date(date);

   // Set the first and last days of the week (Monday-Sunday)
   firstDayOfWeek.setDate(
    date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
   ); // Start Monday
   lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6); // End Sunday

   let formatDate = d =>
    `${String(d.getDate()).padStart(2, "0")}/${String(
     d.getMonth() + 1
    ).padStart(2, "0")}/${d.getFullYear()}`;
   return `${formatDate(firstDayOfWeek)} - ${formatDate(lastDayOfWeek)}`;
  },
 },
};
