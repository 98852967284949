export default {
 data() {
  return { errorStatus: "", errorStatusText: "", errorDataResponse: "" };
 },
 methods: {
  errorHandling(err) {
   console.error(err);
   if (err.response && err.response.status == 401) {
    if (this.$route.path.includes("dashboard")) {
     this.$router.push("/tickets");
    } else {
     localStorage.removeItem("token");
     localStorage.removeItem("adminToken");
     this.$store.dispatch("token", null);
     this.$store.dispatch("adminToken", null);
     this.$router.push("/login");
    }
   } else if (err.response && err.response.status == 403) {
    if (this.$route.path.includes("dashboard")) {
     this.$router.push("/tickets");
    } else {
     this.errorStatus = err.response.status;
     this.errorStatusText = err.response.statusText;
     this.$router.push("/");
    }
   } else if (localStorage.getItem("account") === "ADMIN") {
    if (this.$route.name !== "Impersonate") {
     this.$router.push("/impersonate").then(() => location.reload());
    }
   } else {
    this.errorStatus = err.response.status;
    this.errorStatusText = err.response.statusText;
    if (err.response.data.response) {
     this.errorDataResponse = err.response.data.response;
    } else if (err.response.data.message) {
     this.errorDataResponse = err.response.data.message;
    }
   }
  },
 },
};
